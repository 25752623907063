import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ConfigurationService } from './shared/config.data';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { CoreModule } from './core/core.module';
import { AccountModule } from './account/account.module';
import { SharedModule } from './shared/shared.module';
import { ActivitiesModule } from './activities/activities.module';
import { HttpIntercepterAuth } from './core/http.interceptor';
import { ProfileModule } from './profile/profile.module';

import { ToastrModule } from 'ngx-toastr';
import { AuthService } from './core/authentication/auth.service';
import { LookupService } from './shared/lookup.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IncomeModule } from './income/income.module';
import { ReferralModule } from './referrals/referrals.module';
import { NotificationDeliveryTypePipe } from './core/pipes/deliverytype-pipe';
@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    CoreModule,
    AccountModule,
    IncomeModule,
    FormsModule,
    SharedModule,
    ReferralModule,
    NgxSpinnerModule,
    NgbModule,
    ActivitiesModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    ProfileModule,
    RouterModule.forRoot([
      { path: '', redirectTo: '/activities', pathMatch: 'full' }
    ])
  ],
  exports: [
    NotificationDeliveryTypePipe
  ],
  providers: [ConfigurationService,
    {
      provide: APP_INITIALIZER,
      useFactory: (config: AuthService) => () => config.load(), deps: [AuthService], multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpIntercepterAuth, multi: true },
    LookupService
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
